export function validateCodeComerce(code: string): boolean {
    const count: number = parseInt(code);
    const codigosValidos: number[] = [
      11001, 11002, 11007, 11015, 11016, 11018, 11019, 11020,
      11022, 11023, 11026, 11027, 11030, 11031, 11032, 11033,
      11035, 11036, 11039, 11040, 11041, 11045, 11046, 11047, 11048,
      11047, 11051, 11054, 11055, 11056, 11078, 11079, 11080,
      11081, 11084, 11085, 11089, 11092, 11094, 11097, 11098,
      11099, 11101, 11102, 11103, 11104, 11105, 11108, 11109,
      11111, 11113, 11115, 11116, 11118, 11124, 11130, 11136,
      11152, 11159, 11162, 11163, 11165, 11166, 11167, 11168,
      11169, 11170, 11171, 11173, 11174, 11176, 11177, 11178,
      11179, 11180, 11181, 11182, 11183, 11185, 11186, 11187,
      11188, 11192, 11193, 11194, 11196
    ];
    return codigosValidos.includes(count);
  }

export function cleanText(value: string): string {
    let cleanedValue = value.replace(/^\s*$(?:\r\n?|\n)/gm, '');
    cleanedValue = cleanedValue.split('\n').map(line => line.trim()).join('\n');
    cleanedValue = cleanedValue.replace(/\s\s+/g, ' ');
    cleanedValue = cleanedValue.replace(/\n$/, '');
    return cleanedValue;
  }