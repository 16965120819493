import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import { VIRTUAL_EVENT_VALUES_REMITTANCES } from '@shared/constants/remittances.constant';
import tabsRemittancesItems from '@shared/data/remittances/five-agencies.json';
import { Agences, MapGoogle } from '@shared/interfaces/frontend/remittances';
import { ButtonRemittancesDetailModel, ButtonRemittancesModel } from '@shared/models/remittances.model';
import { DataLayerService } from '@shared/services/data-layer.service';


@Component({
  selector: 'shipping-to-five-agencies',
  templateUrl: './shipping-to-five-agencies.component.html',
  styleUrls: ['./shipping-to-five-agencies.component.scss'],
})
export class LandingRemittancesFiveAgenciesComponent implements OnInit {

  @Input() selectedFaqButton!: ButtonRemittancesModel;
  selectedFaqChildButton: number | null = null;
  @Input() isVisibleButton: boolean[] = [];
  @Output() questionClick = new EventEmitter<string>();
  isClicked: boolean = false;
  isScrolled: boolean = false;
  @Input() agences: Agences[] = [];

  tabsRemittancesItems!: any;
  positionSelected!: google.maps.Marker;
  currentPosition!: google.maps.Marker;
  newGeolocation: any = {};
  currentGeolocation: any = {};
  googlePlaceSelected = false;
  geocoder = new google.maps.Geocoder();
  isIndependent: boolean = true;

  mostrarTextoCompleto: boolean = false;

  activedTagOne: boolean = true;
  activedTagTwo: boolean = false;
  activedTagThree: boolean = false;
  activedTagFour: boolean = false;
  activedTagFive: boolean = false;
  dataMap!: MapGoogle;

  miBanco =
    'https://www.google.com/maps/place/Mibanco/@-12.1195613,-77.0303024,18.5z/data=!4m6!3m5!1s0x9105c819d68cc7d9:0x307b7a0e6f6b3e95!8m2!3d-12.1196769!4d-77.0299754!16s%2Fg%2F11btmqwj06?hl=es-419&entry=ttu';

  constructor(private dataLayerService: DataLayerService,
    public sanitizer: DomSanitizer
  ) {
    this.dataMap = { lat: -12.14883, lng: -76.986471 };
  }

  ngOnInit() {
    this.tabsRemittancesItems = tabsRemittancesItems.tabsRemittances;
    this.isVisibleButton = this.faqDetailsButton.map((_, index) => index === 0);
  }

  toggleSeleccion(elemento: any) {
    elemento.seleccionado = !elemento.seleccionado;
  }


  showOptions(value: string, title: string ){
    this.dataLayerPush(title);
   switch (value) {
    case 'tab1':
      this.activedTagOne= true;
      this.activedTagTwo = false;
      this.activedTagThree = false;
      this.activedTagFour = false;
      this.activedTagFive = false;
      break;
    case 'tab2':
      this.activedTagTwo = true;
      this.activedTagOne = false;
      this.activedTagThree = false;
      this.activedTagFour = false;
      this.activedTagFive = false;
      break;
    case 'tab3':
      this.activedTagThree = true;
      this.activedTagTwo = false;
      this.activedTagOne = false;
      this.activedTagFour = false;
      this.activedTagFive = false;
      break;
    case 'tab4':
      this.activedTagFour = true;
      this.activedTagTwo = false;
      this.activedTagOne = false;
      this.activedTagThree= false;
      this.activedTagFive = false;
      break;
    case 'tab5':
      this.activedTagFive = true;
      this.activedTagTwo = false;
      this.activedTagOne = false;
      this.activedTagFour = false;
      this.activedTagThree = false;
      break;
   }
  }

  public get faqDetailsButton(): ButtonRemittancesDetailModel[] {
      return this.selectedFaqButton?.details.slice().sort(
        (prev, curr) => prev.order - curr.order
      );
    }
  
    questionOnClick(index: number , title:string ) {
      this.dataLayerPush(title);
      this.isVisibleButton[index] = !this.isVisibleButton[index];
      for (let i = 0; i < this.isVisibleButton.length; i++) {
        if (index != i) {
          this.isVisibleButton[i] = false;
        }
      }
      if (this.isVisibleButton[index]) {
        this.questionClick.emit(this.faqDetailsButton[index].title);
      }
    }

    changeIframeMap( url: string) : SafeResourceUrl {
       return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    dataLayerPush(option: string): void {
      this.dataLayerService.virtualEventEnlaceREM(VIRTUAL_EVENT_VALUES_REMITTANCES.selectAgenceRemittancesProgramPage + option, VIRTUAL_EVENT_VALUES_REMITTANCES.selectSectionAgence);
    }

    handleKeyUp(event: Event) {
      // comment explaining why the method is empty
    }
}
