import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

/** Generate an NgbDate from string (yyyy_mm_dd)*/
export function generateNgbDate(strDate: string | null, delimeter: string = '-') {
  let values: string[] = strDate?.split(delimeter)!;
  return new NgbDate(Number(values[0]), Number(values[1]), Number(values[2]));
}

/** Generate a JS Date Object from NgbDate*/
export function generateDateFromNgbDate(ngbDate: NgbDate, addDays: number = 0): Date {
  let date = new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day);
  if (addDays) date.setDate(date.getDate() + addDays);
  return date;
}

/** Generate a string (yyyy-mm-dd) from JS Date*/
export function generateStringFromDate(jsDate: Date): string {
  return jsDate.getUTCFullYear() + '-' + (jsDate.getMonth() + 1) + '-' + jsDate.getDate();
}

/** Generate minDate for datepicker from today */
export function generateLimitDateFromToday(addDays: number = 0, decreaseDays: number = 0): NgbDate {
  let currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + (addDays ? addDays : decreaseDays * -1));
  return new NgbDate(currentDate.getUTCFullYear(), currentDate.getMonth() + 1, currentDate.getDate());
}

export function getDays(): { label: string, value: string }[] {
  return Array.from({ length: 31 }, (_, index) => {
    const day = (index + 1).toString().padStart(2, '0');
    return { label: day, value: day };
  });
}

export function getDaysValidate(month: number, year: number): { label: string, value: string }[] {
  let daysInMonth = 31;

  if (month === 4 || month === 6 || month === 9 || month === 11) {
    daysInMonth = 30;
  } else if (month === 2) {
    daysInMonth = isLeapYear(year) ? 29 : 28;
  }

  return Array.from({ length: daysInMonth }, (_, index) => {
    const day = (index + 1).toString().padStart(2, '0');
    return { label: day, value: day };
  });
}

export function getYears(startYear:number,endYear:number): { label: string, value: string }[] {
  return Array.from({ length: endYear - startYear + 1 }, (_, index) => {
    const year = (endYear - index).toString();
    return { label: year, value: year };
  });
}

function isLeapYear(year: number): boolean {
  return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
}

