import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IDropdownItem } from '@shared/interfaces/IDropdownItem';

@Component({
  selector: 'yevo-dropdown-birthdate',
  templateUrl: './dropdown-birthdate.component.html',
  styleUrls: ['./dropdown-birthdate.component.scss']
})
export class DropdownBirthdateComponent implements OnInit {
  @Output() birthdateSelected = new EventEmitter<{ date: string }>();
  @Input() invalid = false;

  days: IDropdownItem[] = [];
  daySelected!: IDropdownItem;
  months: IDropdownItem[] = [];
  monthSelected!: IDropdownItem;
  years: IDropdownItem[] = [];
  yearSelected!: IDropdownItem;

  constructor() { 
    // NOTHING TO DO
  }

  ngOnInit(): void {
    this.initDateData();
  }

  initDateData(): void {

    this.days = Array.from({ length: 31 }, (_, index) => {
      const day = (index + 1).toString().padStart(2, '0');
      return { label: day, value: day };
    });

    const monthNames = ['ENE', 'FEB', 'MAR', 'ABR', 'MAY', 'JUN', 'JUL', 'AGO', 'SEP', 'OCT', 'NOV', 'DIC'];
    this.months = monthNames.map((month, index) => ({ label: month, value: (index + 1).toString().padStart(2, '0') }));

    const startYear = 1940;
    const endYear = 2005;
    this.years = Array.from({ length: endYear - startYear + 1 }, (_, index) => {
      const year = (endYear - index).toString();
      return { label: year, value: year };
    });
  }

  handleDayEvent(event: any) {
    this.daySelected = event.item;
    this.validateDate();
  }
  handleMonthEvent(event: any) {
    this.monthSelected = event.item;
    this.validateDate();
  }
  handleYearEvent(event: any) {
    this.yearSelected = event.item;
    this.validateDate();
  }

  validateDate() {
    if (this.daySelected && this.monthSelected && this.yearSelected) {
      const selectedDate = `${this.yearSelected.value}-${this.monthSelected.value}-${this.daySelected.value}`;
      this.birthdateSelected.emit({ date: selectedDate });
    }
  }
}
