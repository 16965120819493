import { DecimalPipe } from '@angular/common';
import { Component, ElementRef, HostListener, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { buttonClassNames } from '@shared/components/button/button.component';
import { ILoanRequestCardInfo } from '@shared/components/loan-request-card/loan-request-card.component';
import {
  APPROVED_TYPE_RESPONSES,
  DERIVATION_TYPES,
  EntrepreneurWomanIncompleteStepsLabel,
  FORM_TYPES,
  FinancingIncompleteStepsIndex,
  REJECT_CODE_LIST,
  SeedCashIncompleteStepsLabel,
  TYPES_CARD_REQUEST,
} from '@shared/constants/financing.constants';
import { getModalOptionsWithCustomClass } from '@shared/constants/ui-modal.constants';
import {
  CDECardInfo,
  EXTERNAL_CDE_FORM_ROUTE,
  EXTERNAL_FINANCING_FORM_ROUTE,
  EXTERNAL_FINANCING_PREAPPROVED_FORM_ROUTE,
  FINANCING_DIGITAL_APPROVED_FORM_ROUTE,
  INTERNAL_CDE_FORM_ROUTE,
  INTERNAL_FINANCING_FORM_ROUTE,
  INTERNAL_FINANCING_PREAPPROVED_FORM_ROUTE,
  FIRST_CREDIT_FORM_ROUTE,
  approvedSecondProvision,
  digitalApprovedCardInfo,
  firstCreditCardInfo,
  evaluationCardInfo,
  kushkaCardInfo,
  recomendCardInfo,
  recommendedCardInfo,
  seedCardInfo,
} from '@shared/data/financing/financing-filter-data.json';
import { AssessCreditsExternalRequest, AssessEligibilityExternalRequest } from '@shared/interfaces';
import { UserProfile } from '@shared/models/UserProfileModel';
import { AuthService } from '@shared/services/auth.service';
import { DataLayerService } from '@shared/services/data-layer.service';
import { FinancingService } from '@shared/services/financing.service';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { SeedCashService } from '@shared/services/seed-cash.service';
import { UberService } from '@shared/services/uber.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'yevo-financing-filter',
  templateUrl: './financing-filter.component.html',
  styleUrls: ['./financing-filter.component.scss'],
})
export class FinancingFilterComponent implements OnInit, OnDestroy {
  @ViewChild('modalDisclaimerContent', { static: true }) modalDisclaimerContent!: TemplateRef<any>;
  @ViewChild('modalDisclaimerContentNotice', { static: true }) modalDisclaimerContentNotice!: TemplateRef<any>;
  @ViewChild('modalUberContent', { static: true }) modalUberContent!: TemplateRef<any>;
  @ViewChild('modalEntrepreneurWomanContent', { static: true }) modalEntrepreneurWomanContent!: TemplateRef<any>;
  @ViewChild('ctrl') ctrlInput!: ElementRef;
  @ViewChild('removedAllied', { static: true }) removedAllied: any;

  amountRequested!: number;
  amountRequestedMax: number = 250000;
  amountRequestedClient!: string;
  minimumAmountAllowed!: number;
  isFirstViewLoading = true;
  isLoading = false;
  isAmountEditable = true;
  isAmountFormVisible!: boolean;
  isUnsuccessMessageVisible!: boolean;
  isLowAmountMessageVisible!: boolean;
  isOkAmountMessageVisible!: boolean;
  isSeedCardVisible!: boolean;
  isEvaluationCardVisible!: boolean;
  isSecondProvisionVisible!: boolean;
  isRecommendedCardVisible!: boolean;
  isKushkaCardVisible!: boolean;
  isDigitalApprovedCardVisible!: boolean;
  isFirstCreditCardVisible!: boolean;
  isCDECardVisible!: boolean;
  submitClassName: buttonClassNames = 'primary';
  submitButtonLabel = 'Continuar';
  MAX_SEED_AMOUNT_ALLOWED = 1000;
  isLogged!: boolean;
  isExternalFinancing: boolean = false;

  leadAmountPreApproved!: number;
  leadTypePreApproved!: number;
  solicitudeAmountPreApproved!: number;
  solicitudeTypePreApproved!: number;

  isSeedCardOrder!: number;
  isEvaluationCardOrder!: number;
  isSecondProvisionOrder!: number;
  isRecommendedCardOrder!: number;
  isKushkaCardOrder!: number;
  isDigitalApprovedCardOrder!: number;
  isFirstCreditCardOrder!: number;
  isCDECardOrder!: number;

  isChecked: boolean = true;
  firstCreditAmountPreApproved!: number;
  CDEAmountPreApproved!: number;

  recommendedCardInfo: ILoanRequestCardInfo = recommendedCardInfo;
  seedCardInfo: ILoanRequestCardInfo = seedCardInfo;
  evaluationCardInfo: ILoanRequestCardInfo = evaluationCardInfo;
  approvedSecondProvision: ILoanRequestCardInfo = approvedSecondProvision;
  kushkaCardInfo: ILoanRequestCardInfo = kushkaCardInfo;
  recomendCardInfo: ILoanRequestCardInfo = recomendCardInfo;
  digitalApprovedCardInfo: ILoanRequestCardInfo = digitalApprovedCardInfo;
  firstCreditCardInfo: ILoanRequestCardInfo = firstCreditCardInfo;
  CDECardInfo: ILoanRequestCardInfo = CDECardInfo;

  currentFinancingFormRoute!: string[];
  assessCreditsExternalRequest: AssessCreditsExternalRequest = {
    idPreCredit: this.financingService?.assessEligibilityExternalResponse?.idPreCredit,
    amount: 0,
    aliado: '',
    utmSource: '',
    utmMedium: '',
    utmCampaign: '',
    utmId: '',
  };

  aliado: string = '';
  utm_campaign: string = '';
  utm_medium: string = '';
  utm_source: string = '';
  utm_id: string = '';

  unsuccessMessageType: number = 0;
  unsuccessMessageTypeList = REJECT_CODE_LIST;
  DEFAUL_REJECT_CODE: number = REJECT_CODE_LIST.NEGATIVE_BASE_CODE;
  isShowUnsuccessMessage: boolean = false;

  screenWidth: number = 0;
  modalReference!: NgbModalRef;

  isAllyBannerVisible: boolean = false;
  isAllyBannerVisibleMobile: boolean = false;
  allyBannerIdentifier: string = '';

  isValid: boolean = false;
  isMobile: boolean = false;

  isUberBusiness = false;
  isEntrepreneurWomanBusiness = false;
  isEntrepreneurWomanFlow = false;

  readonly UBER_IDENTIFIER: string = 'uber';
  readonly ENTREPRENEUR_WOMAN_IDENTIFIER: string = 'comunidademprendemujer';
  readonly CALCULATOR_STEP_LABEL: string = 'CALCULADORA';

  isDigitalApprovedTwoCardsShowed: boolean = false;
  isActiveStepThree: boolean = false;
  isActiveStepFour: boolean = false;
  currentPrecreditStep: number = 0;

  preCreditInternal: number = 0;

  assessEligibilityInternalRequest: AssessEligibilityExternalRequest;
  userLogged!: UserProfile | null;
  isLoggedValidate: boolean = false;
  validateStep: number = 0;
  isYape: boolean = false;
  isEligible: boolean = false;
  ally_was_alicorp: boolean = false;
  breadcrumbs = [
    { pageName: 'Inicio', url: ['/'], active: true },
    { pageName: 'Financiamiento', url: null, active: false },
  ];
  typeForm: string = '';
  referredCode!: string;

  constructor(
    public financingService: FinancingService,
    public authService: AuthService,
    private router: Router,
    private decimalPipe: DecimalPipe,
    private seedCashService: SeedCashService,
    public dataLayerService: DataLayerService,
    private rutaActiva: ActivatedRoute,
    private modalService: NgbModal,
    private uberService: UberService,
    private localStorageService: LocalStorageService
  ) {
    const state = window.history?.state;
    if (state?.alliedWasAlicorp) {
      this.ally_was_alicorp = true;
    }

    this.isExternalFinancing = this.financingService.isExternalFinancing;
    this.validateIsLogged();
    this.getParamsShared();
    this.getScreenSize();
    this.rutaActiva.queryParams.subscribe((params) => {
      const business = params['negocio'] || params['aliado'];

      if (business) {
        this.isEntrepreneurWomanBusiness = business.toLowerCase() === this.ENTREPRENEUR_WOMAN_IDENTIFIER;
      }
    });
    this.authService.isLogged$.subscribe((logged) => {
      if (logged) {
        this.breadcrumbs = [
          { pageName: 'Inicio', url: ['/nuevo-intranet', 'dashboard'], active: true },
          { pageName: 'Financiamiento', url: null, active: false },
        ];
      }
    });

    this.assessEligibilityInternalRequest = this.financingService.initAssessEligibilityInternalRequestObject();
  }

  ngOnInit(): void {
    this.removeAllyParam();
    this.financingService.solicitudeId = '';
    this.financingService.clearUploadedFiles();

    this.loadLoggedUser();
    this.getParamsCodeRefers();
    this.openNavigatorOS();
    this.clearSimulatorData();
    this.financingService.selectTypeCardStep.seed = false;
    this.rutaActiva.queryParams.subscribe((params) => {
      this.removeAllyParam();
    });
  }

  ngOnDestroy(): void {
    this.dismissModalReference();
  }

  private loadLoggedUser() {
    this.authService.userProfile$.pipe(take(1)).subscribe((user) => {
      this.userLogged = user;
      this.getParamsInternal();
      this.isLogged = !!user;

      if (this.isLogged) {
        this.financingService.isExternalFinancing = false;
        this.isExternalFinancing = false;
        this.financingService.clearAssessEligibilityExternalRequest();
      } else {
        if (!this.isExternalFinancing) {
          this.router.navigate(['financiamiento', 'solicitud'], { queryParamsHandling: 'merge' });
        }
      }
      this.setInitialValues();
    });
  }

  validateIsLogged() {
    this.authService.isLogged$.subscribe((logged) => {
      if (logged) {
        this.isLoggedValidate = true;
      } else {
        this.isLoggedValidate = false;
      }
    });
  }

  setInitialValues() {
    if (!this.isExternalFinancing) {
      const queryParams = { ...this.rutaActiva.snapshot.queryParams };
      if (!['alicorp', 'uber'].includes(queryParams.aliado)) {
        this.assessEligibility();
      }
      this.currentFinancingFormRoute = INTERNAL_FINANCING_FORM_ROUTE;
    } else {
      this.isFirstViewLoading = false;
      this.showOnlyAmountForm();
      this.currentFinancingFormRoute = EXTERNAL_FINANCING_FORM_ROUTE;
    }
  }

  getParamsInternal() {
    this.assessEligibilityInternalRequest.typeDocument = this.userLogged?.typeDocument || '';
    this.assessEligibilityInternalRequest.documentNumber = this.userLogged?.documentNumber ?? '';
    this.assessEligibilityInternalRequest.documentNumberConfirmation = this.userLogged?.documentNumber ?? '';
    this.assessEligibilityInternalRequest.phone = this.userLogged?.phone ?? '';
    this.assessEligibilityInternalRequest.email = this.userLogged?.email ?? '';
    this.assessEligibilityInternalRequest.aliado = this.aliado;
    this.assessEligibilityInternalRequest.utmSource = this.utm_source;
    this.assessEligibilityInternalRequest.utmMedium = this.utm_medium;
    this.assessEligibilityInternalRequest.utmCampaign = this.utm_campaign;
    this.assessEligibilityInternalRequest.utmId = this.utm_id;
  }

  assessEligibility() {
    if (this.localStorageService.get('ally_was_uber') === 'true') {
      this.assessEligibilityInternalRequest.aliado = 'uber';
    }
    if (this.localStorageService.get('ally_was_alicorp') === 'true') {
      this.assessEligibilityInternalRequest.aliado = 'alicorp';
    }
    this.financingService.assessEligibility(this.assessEligibilityInternalRequest).subscribe((resp) => {
      this.preCreditInternal = resp?.idPreCredit ?? 0;
      this.financingService.idPreCredit = this.preCreditInternal;

      this.financingService.clearInitAssessCardTypeRequestObject();

      this.financingService.changeIdPreCredit(resp?.idPreCredit ?? 0);
      this.financingService.changeStepGeneral(2);
      this.financingService.initAssessCardTypeRequestObject();

      const isEntrepreneur = this.isLogged && this.isEntrepreneurWomanBusiness;
      const data = this.getStatusStepInitialData(isEntrepreneur);

      if (resp?.idPreCredit) {
        this.financingService.updatePrecreditState(`${resp?.idPreCredit}`, data).subscribe();
      }

      this.isFirstViewLoading = false;
      if (resp.isEligible) {
        this.isEligible = true;
        this.showOnlyAmountForm();
        if (isEntrepreneur) this.openEntrepreneurWomanModal();
      } else {
        this.isEligible = false;
        let rejectCode = resp.rejectedCode == 11 ? 1 : resp.rejectedCode;
        this.showOnlyUnsuccessMessage(rejectCode);
      }
    });
  }

  private getStatusStepInitialData(isEntrepreneur: boolean): any {
    return {
      campaignType: '',
      formType: isEntrepreneur ? FORM_TYPES.ENTREPRENEUR_WOMAN : '',
      stepNumber: isEntrepreneur
        ? FinancingIncompleteStepsIndex.STEP_1.toString()
        : FinancingIncompleteStepsIndex.STEP_2.toString(),
      stepDescription: isEntrepreneur ? EntrepreneurWomanIncompleteStepsLabel.STEP_1 : this.CALCULATOR_STEP_LABEL,
    };
  }

  handleSeedCardClick(cardInfo: ILoanRequestCardInfo) {
    this.dataLayerService.pushFinancingDerivation(
      this.isExternalFinancing,
      DERIVATION_TYPES.SEEDCASH,
      cardInfo.amountRequested!
    );

    this.seedCashService.isDirectSeedCash = false;
    this.financingService.derivationSelected = DERIVATION_TYPES.SEEDCASH;
    this.seedCashService.defaultLeadData.cboMontoSolicitado = cardInfo.amountRequested!;
    this.seedCashService.defaultLeadData.txtDocumento =
      this.financingService.assessEligibilityExternalRequest?.documentNumber || '';
    this.seedCashService.defaultLeadData.txtCelular =
      this.financingService.assessEligibilityExternalRequest?.phone || '';
    this.seedCashService.defaultLeadData.txtCorreo =
      this.financingService.assessEligibilityExternalRequest?.email || '';

    this.financingService.activeStepGeneral.subscribe((resp: any) => {
      this.validateStep = resp;
    });

    if (!this.financingService.selectTypeCardStep.seed) {
      this.currentPrecreditStep = 4;
      this.financingService.changeStepGeneral(4);
      this.seedCashService.updateIncompleteState(
        FinancingIncompleteStepsIndex.STEP_4,
        SeedCashIncompleteStepsLabel.STEP_4
      );

      this.financingService.selectTypeCardStep.seed = true;
    }

    this.router.navigate(['/efectivo-semilla', 'paso-1'], {
      state: { source: 'filter', wasAlicorp: this.ally_was_alicorp },
      queryParamsHandling: 'merge',
    });

    sessionStorage.removeItem('finishedStep1');
    sessionStorage.removeItem('finishedStep2');
    sessionStorage.removeItem('finishedStep3');
  }

  handleEvaluationCardClick(cardInfo: ILoanRequestCardInfo) {
    this.financingService.activeStepGeneral.subscribe((resp: any) => {
      this.validateStep = resp;
    });

    if (!this.financingService.selectTypeCardStep.general) {
      this.currentPrecreditStep = 4;
      this.financingService.changeStepGeneral(4);
      let urlTree = this.router.parseUrl(this.router.url);
      let aliado = urlTree.queryParams.aliado;
      if (aliado == undefined) {
        this.getStatusStepForm('0', '4', 'FORMULARIO DE SOLICITUD', this.validateFormType());
      } else {
        if (aliado.toUpperCase() !== FORM_TYPES.UBER)
          this.getStatusStepForm('0', '4', 'FORMULARIO DE SOLICITUD', this.validateFormType());
      }
      this.financingService.selectTypeCardStep.general = true;
    }

    this.dataLayerService.pushFinancingDerivation(
      this.isExternalFinancing,
      DERIVATION_TYPES.EVALUATION,
      cardInfo.amountRequested!
    );
    this.financingService.typeCampaign = cardInfo.typePreApproved!;
    this.financingService.derivationSelected = DERIVATION_TYPES.EVALUATION;
    this.financingService.initialFormAmount = cardInfo.amountRequested!;
    this.financingService.typeCardSelected = TYPES_CARD_REQUEST.EVALUATION;

    this.router.navigate(this.currentFinancingFormRoute, {
      queryParamsHandling: 'merge',
      state: {
        wasAlicorp: this.ally_was_alicorp,
      },
    });
  }

  handleRecommendedCardClick(cardInfo: ILoanRequestCardInfo) {
    this.dataLayerService.pushFinancingDerivation(
      this.isExternalFinancing,
      DERIVATION_TYPES.PREAPPROVED,
      cardInfo.amountRequested!
    );

    this.dataLayerService.pushPreapproveModalLocationButtonEvent('Aviso Obligatorio', '', 'preaprobado digital');

    this.financingService.typeCampaign = cardInfo.typePreApproved!;
    this.financingService.derivationSelected = DERIVATION_TYPES.RECOMMENDED;
    this.financingService.initialFormAmount = cardInfo.amountRequested!;

    this.evaluateMobileRedirectionView('preaprobado');
  }

  handleCDECardClick(cardInfo: ILoanRequestCardInfo) {
    this.dataLayerService.pushFinancingDerivation(
      this.isExternalFinancing,
      DERIVATION_TYPES.CDE,
      cardInfo.amountRequested!
    );

    this.dataLayerService.pushPreapproveModalLocationButtonEvent('Aviso Obligatorio', '', 'deuda');

    this.financingService.amountPreApproved = this.CDEAmountPreApproved;
    this.financingService.typeCampaign = cardInfo.typePreApproved!;
    this.financingService.derivationSelected = DERIVATION_TYPES.CDE;
    this.financingService.initialFormAmount = cardInfo.amountRequested!;

    this.evaluateMobileRedirectionView('cde');
  }

  handleKushkaCardClick(cardInfo: ILoanRequestCardInfo) {
    this.dataLayerService.pushFinancingDerivation(
      this.isExternalFinancing,
      DERIVATION_TYPES.KUSHKA,
      cardInfo.amountRequested!
    );
    this.financingService.typeCampaign = cardInfo.typePreApproved!;
    this.financingService.derivationSelected = DERIVATION_TYPES.KUSHKA;
    this.financingService.initialFormAmount = cardInfo.amountRequested!;

    this.router.navigate(this.currentFinancingFormRoute, {
      queryParamsHandling: 'merge',
      state: {
        wasAlicorp: this.ally_was_alicorp,
      },
    });
  }

  handleDigitalApprovedCardClick(cardInfo: ILoanRequestCardInfo) {
    this.dataLayerService.pushFinancingDerivation(
      this.isExternalFinancing,
      DERIVATION_TYPES.DIGITAL_APPROVED,
      cardInfo.amountRequested!
    );

    this.dataLayerService.pushPreapproveModalLocationButtonEvent('Aviso Obligatorio', '', 'aprobado digital');

    this.financingService.typeCampaign = cardInfo.typePreApproved!;
    this.financingService.derivationSelected = DERIVATION_TYPES.DIGITAL_APPROVED;
    this.financingService.initialFormAmount = cardInfo.amountRequested!;

    this.evaluateMobileRedirectionView('aprobadodigital');
  }

  handleFirstCreditCardClick(cardInfo: ILoanRequestCardInfo) {
    this.dataLayerService.pushFinancingDerivation(
      this.isExternalFinancing,
      DERIVATION_TYPES.FIRST_CREDIT,
      cardInfo.amountRequested!
    );

    this.dataLayerService.pushPreapproveModalLocationButtonEvent('Aviso Obligatorio', '', 'mi primer crédito');

    this.financingService.amountPreApproved = this.firstCreditAmountPreApproved;
    this.financingService.typeCampaign = cardInfo.typePreApproved!;
    this.financingService.derivationSelected = DERIVATION_TYPES.FIRST_CREDIT;
    this.financingService.initialFormAmount = cardInfo.amountRequested!;

    this.evaluateMobileRedirectionView('miprimercredito');
  }

  saveUberData(data: any, params: string = '') {
    const dataToSave = {
      ...data,
      idPreCredit:
        this.financingService?.assessEligibilityExternalResponse?.idPreCredit || this.financingService?.idPreCredit,
      documentType: this.financingService.assessEligibilityExternalRequest?.typeDocument || '',
      documentNumber: this.financingService.assessEligibilityExternalRequest?.documentNumber || '',
      phoneNumber: this.financingService.assessEligibilityExternalRequest?.phone || '',
      email: this.financingService.assessEligibilityExternalRequest?.email || '',
    };
    this.uberService.saveData(dataToSave, params);
  }

  evaluateMobileRedirectionView(flowType: 'preaprobado' | 'aprobadodigital' | 'cde' | 'miprimercredito') {
    let flowTypeLabel = 'preaprobado digital';
    if (this.isMobile) {
      if (flowType === 'preaprobado') {
        this.currentFinancingFormRoute = this.isExternalFinancing
          ? EXTERNAL_FINANCING_PREAPPROVED_FORM_ROUTE
          : INTERNAL_FINANCING_PREAPPROVED_FORM_ROUTE;
      } else if (flowType === 'aprobadodigital') {
        flowTypeLabel = 'aprobado digital'
        this.currentFinancingFormRoute = FINANCING_DIGITAL_APPROVED_FORM_ROUTE;
      } else if (flowType === 'cde') {
        flowTypeLabel = 'deuda';
        this.currentFinancingFormRoute = this.isExternalFinancing ? EXTERNAL_CDE_FORM_ROUTE : INTERNAL_CDE_FORM_ROUTE;
      } else {
        flowTypeLabel = 'mi primer crédito'
        this.currentFinancingFormRoute = FIRST_CREDIT_FORM_ROUTE;
      }
      this.openDisclaimerPreapprovedModal(this.modalDisclaimerContent, flowTypeLabel);

    } else {
      this.openDisclaimerPreapprovedModal(this.modalDisclaimerContentNotice, flowTypeLabel);
    }
  }

  sendAmountRequested() {
    this.amountRequested = parseInt(this.amountRequestedClient.replace(',', ''));

    if (this.isEntrepreneurWomanFlow) {
      this.financingService.initialFormAmount = this.amountRequested;
      this.dataLayerService.pushEntrepreneurWomanAmountRequested(this.amountRequested);
      this.router.navigate(['financiamiento', 'emprende-mujer-solicitud'], { queryParamsHandling: 'merge' });

      this.getStatusStepForm(
        '0',
        FinancingIncompleteStepsIndex.STEP_3.toString(),
        EntrepreneurWomanIncompleteStepsLabel.STEP_3,
        FORM_TYPES.ENTREPRENEUR_WOMAN
      );
      return;
    }

    if (!this.isAmountEditable) {
      this.dataLayerService.pushFinancingInputAmount(this.isExternalFinancing, 'Editar');
      this.setSubmitView();
      return;
    }
    this.dataLayerService.pushFinancingInputAmount(this.isExternalFinancing, 'Ingresar');
    this.isLoading = true;
    this.submitButtonLabel = 'Consultando...';
    this.isLoggedValidate ? this.assessCredits() : this.assessCreditsExternal();
  }

  private assessCredits() {
    this.financingService.assessCredits(this.amountRequested,this.financingService.defaultRefersData.referredCode).subscribe(
      (resp) => {
        this.submitClassName = 'link';
        const { isEligible, typeCredits, minimumAmountAllowed } = resp;
        this.financingService.activeStepGeneral.subscribe((resp: any) => {
          this.validateStep = resp;
        });

        if (this.validateUpdateState(3) && this.validateStep !== 4 && this.isLoggedValidate) {
          this.currentPrecreditStep = 3;
          this.getStatusStepForm('', '3', 'SELECCION DE CARD');
        }

        this.hideAllyBanner();
        this.setEditView();
        if (!isEligible) {
          this.ineligibleClientFlow(minimumAmountAllowed);
        } else {
          this.isDigitalApprovedTwoCardsShowed = false;

          typeCredits?.forEach((typeCredit) => {
            if (typeCredit.name === 'Lead') {
              this.showOnlyOkAmountMessage();
              this.defineLeadCardsToShow(
                typeCredit.amountRequested!,
                typeCredit.typePreApproved!,
                typeCredit.order!,
                typeCredit.amountPreApproved
              );
              this.showCardPreAprovedLead(typeCredit.amountPreApproved, typeCredit.typePreApproved);
            } else if (typeCredit.name === 'Solicitude') {
              this.showOnlyOkAmountMessage();
              this.defineSolicitudeCardsToShow(
                typeCredit.amountRequested!,
                typeCredit.typePreApproved!,
                typeCredit.order!,
                typeCredit.campaignEndDate,
                typeCredit.amountPreApproved,
                typeCredit.termNumber,
                typeCredit.termAmount
              );
              this.showCardPreAprovedSolicitude(typeCredit.amountPreApproved, typeCredit.typePreApproved);
            }
          });
        }
        this.isLoading = false;
      },
      (err) => {
        console.error(err);
        this.isLoading = false;
      }
    );
  }

  public showCardPreAprovedLead(amountRequested: any, typePreApproved: any) {
    this.leadAmountPreApproved = amountRequested;
    this.leadTypePreApproved = typePreApproved;
  }

  public showCardPreAprovedSolicitude(amountRequested: any, typePreApproved: any) {
    this.solicitudeAmountPreApproved = amountRequested;
    this.solicitudeTypePreApproved = typePreApproved;
  }

  private assessCreditsExternal() {
    this.assessCreditsExternalRequest.amount = this.amountRequested;
    this.assessCreditsExternalRequest.aliado = this.aliado;
    this.assessCreditsExternalRequest.utmSource = this.utm_source;
    this.assessCreditsExternalRequest.utmMedium = this.utm_medium;
    this.assessCreditsExternalRequest.utmCampaign = this.utm_campaign;
    this.assessCreditsExternalRequest.utmId = this.utm_id;

    this.financingService.assessCreditsExternal(this.assessCreditsExternalRequest,this.financingService.defaultRefersData.referredCode).subscribe(
      (resp) => {
        const { isEligible, typeCredits, minimumAmountAllowed } = resp;

        this.financingService.activeStepGeneral.subscribe((resp: any) => {
          this.validateStep = resp;
        });

        if (this.validateUpdateState(3) && this.validateStep !== 4 && !this.isLoggedValidate) {
          this.currentPrecreditStep = 3;
          this.getStatusStepForm('', '3', 'SELECCION DE CARD');
        }

        this.financingService.idPreCredit = this.financingService?.assessEligibilityExternalResponse?.idPreCredit;
        this.submitClassName = 'link';
        this.hideAllyBanner();
        this.setEditView();
        this.financingService.assessCreditsExternalResponse = resp;
        if (!isEligible) {
          this.ineligibleClientFlow(minimumAmountAllowed);
        } else {
          this.isDigitalApprovedTwoCardsShowed = false;

          typeCredits?.forEach((typeCredit) => {
            if (typeCredit.name === 'Lead') {
              this.showOnlyOkAmountMessage();
              this.defineLeadCardsToShow(
                typeCredit.amountRequested!,
                typeCredit.typePreApproved!,
                typeCredit.order!,
                typeCredit.amountPreApproved
              );
            } else if (typeCredit.name === 'Solicitude') {
              this.showOnlyOkAmountMessage();
              this.defineSolicitudeCardsToShow(
                typeCredit.amountRequested!,
                typeCredit.typePreApproved!,
                typeCredit.order!,
                typeCredit.campaignEndDate,
                typeCredit.amountPreApproved,
                typeCredit.termNumber,
                typeCredit.termAmount
              );
            }
          });
        }
        this.isLoading = false;
      },
      (err) => {
        console.error(err);
        this.isLoading = false;
      }
    );
  }

  private ineligibleClientFlow(minimumAmountAllowed?: number) {
    if (minimumAmountAllowed) {
      this.minimumAmountAllowed = minimumAmountAllowed;
      this.showOnlyLowAmountMessage();
    } else {
      this.showOnlyUnsuccessMessage();
    }
  }

  private defineLeadCardsToShow(
    amountRequested: number,
    typePreApproved: number,
    order: number,
    amountPreApproved: number = 0
  ) {
    if (amountRequested > this.MAX_SEED_AMOUNT_ALLOWED) {
      this.showEvaluationCard(amountRequested, typePreApproved, order, amountPreApproved);
    } else {
      this.showSeedCard(amountRequested, order);
    }
  }

  private defineSolicitudeCardsToShow(
    amountRequested: number,
    typePreApproved: number,
    order: number,
    campaignEndDate: string = '',
    amountPreApproved: number = 0,
    termNumber: number = 0,
    termAmount: number = 0
  ) {
    if (typePreApproved === APPROVED_TYPE_RESPONSES.NO_PRE_APPROVED) {
      if (this.isDigitalApprovedCardVisible) {
        this.isDigitalApprovedTwoCardsShowed = true;
      }
      this.showEvaluationCard(amountRequested, typePreApproved, order, amountPreApproved);
    } else if (typePreApproved === APPROVED_TYPE_RESPONSES.APPROVED) {
      this.showSecondProvisionCard(amountRequested, typePreApproved, order, amountPreApproved);
    } else if (typePreApproved === APPROVED_TYPE_RESPONSES.PRE_APPROVED_KUSHKA) {
      this.showKushkaCard(amountRequested, typePreApproved, order, amountPreApproved);
    } else if (typePreApproved === APPROVED_TYPE_RESPONSES.DIGITAL_PRE_APPROVED) {
      this.showRecommendedCard(
        amountRequested,
        typePreApproved,
        amountPreApproved,
        order,
        campaignEndDate,
        termNumber,
        termAmount
      );
    } else if (typePreApproved === APPROVED_TYPE_RESPONSES.DIGITAL_APPROVED) {
      this.showDigitalApprovedCard(
        amountRequested,
        typePreApproved,
        order,
        campaignEndDate,
        termNumber,
        termAmount,
        amountPreApproved
      );
    } else if (typePreApproved === APPROVED_TYPE_RESPONSES.CDE) {
      this.CDEAmountPreApproved = amountPreApproved;
      this.showCDECard(
        amountRequested,
        typePreApproved,
        order,
        campaignEndDate,
        termNumber,
        termAmount,
        amountPreApproved
      );
    } else if (typePreApproved === APPROVED_TYPE_RESPONSES.FIRST_CREDIT) {
      this.firstCreditAmountPreApproved = amountPreApproved;
      this.showFirstCreditCard(
        amountRequested,
        typePreApproved,
        order,
        campaignEndDate,
        termNumber,
        termAmount,
        amountPreApproved
      );
    }
  }

  private showSeedCard(amountRequested: number, order: number) {
    this.seedCardInfo.amountRequested = amountRequested;
    this.isSeedCardVisible = true;
    this.isSeedCardOrder = order;
  }

  private showEvaluationCard(
    amountRequested: number,
    typePreApproved: number,
    order: number,
    amountPreApproved: number = 0
  ) {
    this.evaluationCardInfo.amountRequested = amountRequested;
    this.evaluationCardInfo.title = `Solicita S/ ${this.decimalPipe.transform(amountRequested)} de préstamo`;
    this.evaluationCardInfo.typePreApproved = typePreApproved;
    this.isEvaluationCardVisible = true;
    this.isEvaluationCardOrder = order;
  }

  private showSecondProvisionCard(
    amountRequested: number,
    typePreApproved: number,
    order: number,
    amountPreApproved: number = 0
  ) {
    this.approvedSecondProvision.amountRequested = amountRequested;
    this.approvedSecondProvision.typePreApproved = typePreApproved;
    this.isSecondProvisionVisible = true;
    this.isSecondProvisionOrder = order;
  }

  private showRecommendedCard(
    amountRequested: number,
    typePreApproved: number,
    amountPreApproved: number,
    order: number,
    campaignEndDate: string,
    termNumber: number,
    termAmount: number
  ) {
    this.recomendCardInfo.title = `¡Tienes un crédito pre-aprobado!`;
    this.recomendCardInfo.message = `Calificas para pedir un monto aprobado de hasta <b>S/ ${this.decimalPipe.transform(
      amountPreApproved
    )}</b> con cuotas de <b>hasta S/ ${termAmount}*</b>`;
    this.recomendCardInfo.linkLabel = `Solicítalo aquí`;
    this.recomendCardInfo.typePreApproved = typePreApproved;
    this.recomendCardInfo.amountRequested = amountPreApproved;
    this.recomendCardInfo.footer = `*Para pagar hasta en ${termNumber} cuotas. Condiciones vigentes hasta ${campaignEndDate}.<br>
    Sujeto a evaluación crediticia a la fecha del desembolso.`;
    this.isRecommendedCardVisible = true;
    this.isRecommendedCardOrder = order;
  }

  private showKushkaCard(
    amountRequested: number,
    typePreApproved: number,
    order: number,
    amountPreApproved: number = 0
  ) {
    this.kushkaCardInfo.amountRequested = amountPreApproved;
    this.kushkaCardInfo.title = `Solicita un préstamo de S/ ${this.decimalPipe.transform(amountPreApproved)}`;
    this.kushkaCardInfo.typePreApproved = typePreApproved;
    this.isKushkaCardVisible = true;
    this.isKushkaCardOrder = order;
  }

  private showDigitalApprovedCard(
    amountRequested: number,
    typePreApproved: number,
    order: number,
    campaignEndDate: string,
    termNumber: number,
    termAmount: number,
    amountPreApproved: number = 0
  ) {
    this.digitalApprovedCardInfo.amountRequested = amountRequested;
    this.digitalApprovedCardInfo.message = `¡Calificas a un monto aprobado de hasta <br class='digitalApprovedCardInfo-amount' /> <b>S/ ${this.decimalPipe.transform(
      amountPreApproved
    )}</b> para pagar en <b>hasta ${termNumber} cuotas<b>`;
    this.digitalApprovedCardInfo.typePreApproved = typePreApproved;
    this.isDigitalApprovedCardVisible = true;
    this.isDigitalApprovedCardOrder = order;
    this.digitalApprovedCardInfo.footer = `*Para pagar hasta en ${termNumber} cuotas. Condiciones vigentes hasta ${campaignEndDate}. <br>Sujeto a evaluación crediticia a la fecha del desembolso.`;
  }

  private showFirstCreditCard(
    amountRequested: number,
    typePreApproved: number,
    order: number,
    campaignEndDate: string,
    termNumber: number,
    termAmount: number,
    amountPreApproved: number = 0
  ) {
    this.firstCreditCardInfo.amountRequested = amountRequested;
    this.firstCreditCardInfo.message = `Calificas para un préstamo de hasta <b>S/ ${this.decimalPipe.transform(
      amountPreApproved
    )}</b> para pagar en <b>hasta ${termNumber} cuotas.<b>`;
    this.firstCreditCardInfo.typePreApproved = typePreApproved;
    this.isFirstCreditCardVisible = true;
    this.isFirstCreditCardOrder = order;
    this.firstCreditCardInfo.footer = `*Para pagar hasta en ${termNumber} cuotas. Condiciones vigentes hasta ${campaignEndDate}. <br>Sujeto a evaluación crediticia a la fecha del desembolso.`;
  }

  private showCDECard(
    amountRequested: number,
    typePreApproved: number,
    order: number,
    campaignEndDate: string,
    termNumber: number,
    termAmount: number,
    amountPreApproved: number = 0
  ) {
    this.CDECardInfo.amountRequested = amountRequested;
    this.CDECardInfo.message = `Calificas para un préstamo de compra deuda de hasta <b>S/ ${this.decimalPipe.transform(
      amountPreApproved
    )}</b> para pagar en <b>hasta ${this.decimalPipe.transform(termNumber)} cuotas.<b>`;
    this.CDECardInfo.typePreApproved = typePreApproved;
    this.isCDECardVisible = true;
    this.isCDECardOrder = order;
    this.CDECardInfo.footer = `*Para pagar hasta en ${termNumber} cuotas. Condiciones vigentes hasta ${campaignEndDate}. <br>Sujeto a evaluación crediticia a la fecha del desembolso.`;
  }

  private showOnlyAmountForm() {
    this.isAmountFormVisible = true;
    this.isOkAmountMessageVisible = false;
    this.isLowAmountMessageVisible = false;
  }

  private showOnlyUnsuccessMessage(rejectCode: number = this.DEFAUL_REJECT_CODE) {
    this.isShowUnsuccessMessage = true;
    this.isAmountFormVisible = false;
    this.isUnsuccessMessageVisible = true;
    this.unsuccessMessageType = rejectCode;
    this.isAllyBannerVisible = false;
    this.isAllyBannerVisibleMobile = false;
  }

  private showOnlyLowAmountMessage() {
    this.isLowAmountMessageVisible = true;
    this.isOkAmountMessageVisible = false;
    this.isAllyBannerVisible = false;
    this.isAllyBannerVisibleMobile = false;
  }

  private showOnlyOkAmountMessage() {
    this.isLowAmountMessageVisible = false;
    this.isOkAmountMessageVisible = true;
    this.isAllyBannerVisible = false;
    this.isAllyBannerVisibleMobile = false;
  }

  private resetCards() {
    this.submitClassName = 'primary';
    this.isSeedCardVisible = false;
    this.isKushkaCardVisible = false;
    this.isEvaluationCardVisible = false;
    this.isRecommendedCardVisible = false;
    this.isOkAmountMessageVisible = false;
    this.isLowAmountMessageVisible = false;
    this.isDigitalApprovedCardVisible = false;
    this.isFirstCreditCardVisible = false;
    this.isCDECardVisible = false;
    this.recommendedCardInfo.message =
      'Envía tu solicitud y un asesor de Mibanco te contactará para el proceso de desembolso.';
    this.recommendedCardInfo.linkLabel = 'Solicitar ahora';
    this.evaluationCardInfo.title = 'Crédito pre-aprobado de hasta S/ @{amount}';
  }

  private setSubmitView() {
    this.isAmountEditable = true;
    this.submitButtonLabel = 'Continuar';
    this.amountRequested = null!;
    this.resetCards();
  }

  private setEditView() {
    this.isAmountEditable = false;
    this.submitButtonLabel = 'Editar Monto';
  }

  public styleIconoInput() {
    let initial: any;
    if (window.innerWidth <= 320) {
      initial = '90';
    } else if (window.innerWidth > 320 && window.innerWidth < 380) {
      initial = '120';
    } else {
      initial = '130';
    }
    const numberDigit = this.amountRequested ? this.amountRequested.toString() : '0';
    const dinamicStyle = initial - numberDigit.length * 10;

    const data = { transform: 'translate(' + dinamicStyle + 'px, -50% )' };

    return data;
  }

  public changeAmountRequest(valor: any) {
    if (valor && this.isAValidNumber(valor)) {
      const numero = parseFloat(valor.replace(/,/g, ''));

      this.amountRequested = numero > this.amountRequestedMax ? this.amountRequestedMax : numero;
      this.financingService.amountRequest = this.amountRequested;
      let number = this.amountRequested;
      this.amountRequestedClient = number.toLocaleString('es-PE');
      this.ctrlInput.nativeElement.value = this.amountRequestedClient;
      this.isValid = true;
    } else {
      this.isValid = false;
      this.amountRequested = 0;
      this.amountRequestedClient = '';
      this.ctrlInput.nativeElement.value = '';
    }
  }

  public isAValidNumber(valor: string) {
    const number = parseFloat(valor.replace(/,/g, ''));
    return !isNaN(number);
  }

  public onChangeCheck(event: any) {
    this.isChecked = true;
  }

  private getParamsShared() {
    const allies = ['wings', 'uber', 'alicorp'];
    this.rutaActiva.queryParams.subscribe((params) => {
      this.aliado = params.aliado || params.negocio || '';
      this.utm_campaign = params.utm_campaign || '';
      this.utm_medium = params.utm_medium || '';
      this.utm_source = params.utm_source || '';
      this.utm_id = params.utm_id || '';

      if (allies.includes(this.aliado)) {
        this.financingService.aliado = this.aliado;
      }
      if (this.aliado == 'yape' && this.isLogged) {
        this.isAllyBannerVisible = false;
        this.isYape = true;
        this.typeForm = 'yape';
      } else if (this.aliado && this.aliado !== 'yape') {
        this.typeForm = this.aliado;
        this.isAllyBannerVisible = false;
        this.isAllyBannerVisibleMobile = false;
      }
    });
  }

  private hideAllyBanner() {
    this.isAllyBannerVisible = false;
    this.isAllyBannerVisibleMobile = false;
  }

  @HostListener('window:resize', ['$event'])
  private getScreenSize() {
    this.screenWidth = window.innerWidth;
  }

  public openDisclaimerPreapprovedModal(content: any, flowTypeLabel: string) {
    this.modalReference = this.modalService.open(content, getModalOptionsWithCustomClass('custom-modal-preapproved'));

    this.modalReference.result.then((result) => {
      this.dataLayerService.pushPreapproveModalLocationButtonEvent('Aviso Obligatorio', 'Comenzar', flowTypeLabel);
      this.router.navigate(this.currentFinancingFormRoute, {
        queryParamsHandling: 'merge',
        state: {
          wasAlicorp: this.ally_was_alicorp,
        },
      });
    });
  }

  public closeDisclaimerPreapprovedModal(): void {
    this.modalReference?.close();
  }
  public dismissDisclaimerPreapprovedModal(): void {
    this.modalReference?.dismiss();
  }

  public dismissNoticePreapprovedModal(): void {
    this.modalReference?.dismiss();
    this.router.navigate(['financiamiento/solicitud'], { queryParamsHandling: 'merge' });
  }

  public dismissUberModal(): void {
    this.modalReference?.dismiss();
  }
  public closeUberModal(): void {
    this.modalReference?.close();
  }

  public openEntrepreneurWomanModal() {
    this.modalReference = this.modalService.open(
      this.modalEntrepreneurWomanContent,
      getModalOptionsWithCustomClass('custom-modal-entrepreneur-woman', 'lg')
    );
    this.dataLayerService.pushEntrepreneurWomanModalOpened();
  }

  public dismissEntrepreneurWomanModal(): void {
    this.modalReference?.dismiss();
    this.isEntrepreneurWomanFlow = false;
    this.removeAllyUrlParam();
    this.dataLayerService.pushEntrepreneurWomanModalSelection('Hombre');
    this.getCalculatorStep();
  }

  private removeAllyUrlParam() {
    this.router.navigate([], {
      queryParams: {
        aliado: null,
      },
      queryParamsHandling: 'merge',
    });
  }

  public closeEntrepreneurWomanModal(): void {
    this.modalReference?.close();
    this.isEntrepreneurWomanFlow = true;
    this.dataLayerService.pushEntrepreneurWomanModalSelection('Mujer');
    this.getCalculatorStep(true);
  }

  private getCalculatorStep(isEntrepreneur: boolean = false): void {
    this.getStatusStepForm('', '2', this.CALCULATOR_STEP_LABEL, isEntrepreneur ? FORM_TYPES.ENTREPRENEUR_WOMAN : '');
  }

  public dismissModalReference(): void {
    this.modalReference?.dismiss();
  }

  openNavigatorOS() {
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }

  private validateUpdateState(stepNumber: number): boolean {
    if (stepNumber > this.currentPrecreditStep) {
      return true;
    }
    return false;
  }

  private getStatusStepForm(campaignType: string, step: string, stepDescription: string, formType: string = '') {
    let preCredit = 0;
    this.financingService.activeIdPreCredit.subscribe((resp: any) => {
      preCredit = resp;
    });
    const data = {
      campaignType: campaignType,
      formType: formType,
      stepNumber: step,
      stepDescription: stepDescription,
      referredCode:this.financingService.defaultRefersData.referredCode,
    };

    if (preCredit) {
      this.setParamsCodePreCredit(preCredit);
      this.financingService.updatePrecreditState(`${preCredit}`, data).subscribe();
    }
  }

  private validateFormType() {
    let urlTree = this.router.parseUrl(this.router.url);
    let aliado = urlTree.queryParams.aliado;
    if (aliado === 'alicorp') return FORM_TYPES.ALICORP;
    return FORM_TYPES.GENERAL;
  }

  private clearSimulatorData() {
    if (this.financingService.simulatorCompleted) {
      this.financingService.simulatorCompleted = false;
      this.financingService.simulatorData = {};
    }
  }

  private removeAllyParam() {
    const queryParams = { ...this.rutaActiva.snapshot.queryParams };
    if (queryParams.aliado == 'wings') {
      delete queryParams.aliado;
    }
    if (['alicorp', 'uber'].includes(queryParams.aliado)) {
      if (queryParams.aliado === 'alicorp') this.ally_was_alicorp = true;
      this.modalService.open(this.removedAllied, {
        centered: true,
        backdrop: 'static',
        keyboard: false,
        size: 'xs',
        windowClass: 'removed-allied-modal',
      });
    }
    this.router.navigate([], {
      relativeTo: this.rutaActiva,
      queryParams,
    });
  }
  public goToGeneralForm(): void {
    const queryParams = { ...this.rutaActiva.snapshot.queryParams };
    if (queryParams.aliado === 'alicorp') {
      this.ally_was_alicorp = true;
      this.localStorageService.set('ally_was_alicorp', 'true');
    }
    if (queryParams.aliado === 'uber') {
      this.localStorageService.set('ally_was_uber', 'true');
    }
    this.modalService.dismissAll();
    const { aliado, ...rest } = queryParams;
    this.router.navigate(['financiamiento', 'solicitud'], {
      state: {
        alliedWasAlicorp: this.ally_was_alicorp,
      },
      queryParams: rest,
    });
  }

  getParamsCodeRefers(){
    if(this.userLogged){
      this.rutaActiva.queryParams.subscribe((params) => {
        if(params['recommend_code']){       
          this.referredCode = params['recommend_code'];
        }else{
          this.referredCode = '';
        }
        this.setParamsCodeRefers(this.referredCode);
      });
    }
  }

  setParamsCodeRefers(codeRefers: string){
    this.financingService.defaultRefersData = {
      ...this.financingService.refersData,
      referredCode:codeRefers,
    }
    this.financingService.setRefersData();
  }

  setParamsCodePreCredit(codePreCredit: number){
    this.financingService.defaultRefersData = {
      ...this.financingService.refersData,
      idPreCredit:codePreCredit,
    }
    this.financingService.setRefersData();
  }
}
