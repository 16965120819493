import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Lookup, LookupExtra, LookupOrder } from '@shared/models';
import { ParamsLookup } from '@shared/models/ParamsLookupType';
import { LeadModel } from '@shared/models/LeadModel';
import { map, take } from 'rxjs/operators';
import { DataPayload, SeedCashMailchimpStep1 } from '@shared/interfaces';
import { FinancingService } from './financing.service';
import { isStateUpdateValid } from '@shared/utils/financing.utils';
import { FORM_TYPES, FinancingIncompleteStepsIndex } from '@shared/constants/financing.constants';
import { LeadBusinessDetailRequest, preapproveImageModel } from '@shared/models/financing.model';
import { LeadBusinessModel } from '@shared/models/LeadBusiness.model';
import { NEW_SEED_FLOW, NEW_SEED_VALUES } from '@shared/constants/seed-cash.constants';
import { validateCodeComerce } from '@shared/utils/seed-cash.utils';
import { FormDataService } from './form-data.service';

@Injectable({
  providedIn: 'root',
})
export class SeedCashService {
  private readonly envBase = `${environment.apiBaseUrl}/api-credit`;

  constructor(private httpClient: HttpClient, private financingService: FinancingService,private formDataService:FormDataService) {}
  private nameSubject = new BehaviorSubject<string>('');
  nameSubjectSearch$ = this.nameSubject.asObservable();

  private stateSource = new BehaviorSubject<string>('');
  stateUrl = this.stateSource.asObservable();
  segment: string = '';
  // PRESERVE DATA IN VIEW
  defaultLeadData: LeadModel = this.initData();
  defaultLeadBusinessData: LeadBusinessModel = this.leadBusinessInitData();
  leadData: LeadModel = this.defaultLeadData;
  leadBusinessData: LeadBusinessModel = this.defaultLeadBusinessData;
  registerStep$: BehaviorSubject<number> = new BehaviorSubject(0);
  prevStep: number = 0;
  successMsgStep4UserLogged = false;

  maritalStatusArray: Lookup[] = [];
  housingTypesArray: Lookup[] = [];
  typeActivitiesArray: Lookup[] = [];
  schedulesArray: Lookup[] = [];
  placesArray: Lookup[] = [];
  commercialBusinessesArray: LookupOrder[] = [];
  numberChildrenArray: Lookup[] = [];
  frequences: LookupExtra[] = [];
  paymentmenthods: Lookup[] = [];
  appsTaxis: Lookup[] = [];
  leadFileData: DataPayload[] =[];

  birthDateHasChanged: boolean = false;
  accountEmail?: string;
  accountPass?: string;

  currentPrecreditStep: number = FinancingIncompleteStepsIndex.STEP_4;

  idSeedCash!: number;
  isDirectSeedCash: boolean = false;

  prefillDataExternally!: any;
  hasPrefillExternally: boolean = false;
  leadDataInvalidEligibility: any;

  initData() {
    this.accountEmail = '';
    this.accountPass = '';

    return {
      cboMontoSolicitado: 0.0,
      cboPlazo1: 0,
      cboPlazo2: 0,
      txtDocumento: '',
      txtDocumentoConfirmation: '',
      txtNombres: '',
      txtApellidoPaterno: '',
      txtApellidoMaterno: '',
      txtCelular: '',
      txtCelularConfirmation: '',
      txtCorreo: '',
      txtFechaNacimiento: null,
      txtHijos: '0',
      cboVivienda: null,
      cboEstadoCivil: null,
      txtTelefonoFijo: null,
      txtDocumentoConyuge: null,
      txtNombresConyuge: null,
      txtApellidosConyuge: null,
      cboTipoDeActividad: null,
      cboGiroComercial: null,
      txtLaboral: null,
      txtNombreNegocio: null,
      txtLaboralIndependiente: null,
      txtAntiguedadComercial: null,
      txtAntiguedadActLaboral: null,
      txtIngresosLaboral: null,
      txtIngresosNegocioFrecuencia: null,
      txtIngresosNegocio: null,
      txtGastoslaborales: null,
      txtComprasNegocioFrecuencia: null,
      txtComprasNegocio: null,
      txtGastosFijosMensual: null,
      txtGastosFamiliar: null,
      txtNombreEmpresa: null,
      txtRucEmpresa: null,
      cboUbigeoNegocioDepartamento: null,
      cboUbigeoNegocioProvincia: null,
      cboUbigeoNegocioDistrito: null,
      cboUbigeoNegocio: null,
      txtDireccionNegocio: null,
      txtDireccionLatitud: null,
      txtDireccionLongitud: null,
      txtTelefonoNegocio: null,
      horario: null,
      lugar: null,
      chkPersonalData: null,
      chkTermsConditions: null,
      interestedInsurance: null,
      recaptcha: null,
      source: null,
      aliado: null,
      utmSource: null,
      utmMedium: null,
      utmCampaign: null,
      utmId: null,
      formType: FORM_TYPES.SEEDCASH,
      finishedStep1: false,
      finishedStep2: false,
      finishedStep3: false,

      cboUbigeoDomicilio: null,
      cboUbigeoDomicilioDepartamento: null,
      cboUbigeoDomicilioProvincia: null,
      cboUbigeoDomicilioDistrito: null,
      txtDireccionDomicilio: null,
      txtDireccionDomicilioLatitud: null,
      txtDireccionDomicilioLongitud: null,
    };
  }

  leadBusinessInitData() {
    return  {
    leadId: 0,
    businessSectorName: '',
    fuelLoadFrequencyTaxi: '',
    taxiLoadAmount: 0,
    workApp: '',
    paymentType: '',
    suppliers:'',
    businessDescription:'',
    }   
  }

  setSegment(value: string) {
    this.segment = value;
  }
  setLeadData() {
    this.leadData = this.defaultLeadData;
  }

  setLeadBusinessData() {
    this.leadBusinessData = this.defaultLeadBusinessData;
  }

  setPrevStep(step: number) {
    this.prevStep = step;
  }

  clearData() {
    this.idSeedCash = 0;
    this.prevStep = 0;
    this.defaultLeadData = this.initData();
  }
  
  clearBusinessData() {
    this.defaultLeadBusinessData = this.leadBusinessInitData();
  }

  clearAllData() {
    this.idSeedCash = 0;
    this.prevStep = 0;
    this.defaultLeadData = this.initData();
    this.leadData = this.initData();
    this.leadFileData = [];
    this.defaultLeadBusinessData = this.leadBusinessInitData();
    this.leadBusinessData = this.leadBusinessInitData();
  }

  clearSegment(){
    this.segment = '';
  }

  // CALL TO LEAD REST API
  headerReCaptcha(reCaptcha: string): any {
    return new HttpHeaders({ 're-captcha-token': reCaptcha });
  }

  getAllDropdowns(): void {
    this.httpClient
      .get<ParamsLookup>(`${this.envBase}/param/lead`)
      .pipe(take(1))
      .subscribe((resp) => {
        this.maritalStatusArray = resp?.maritalStatus;
        this.housingTypesArray = resp?.housingTypes;
        this.typeActivitiesArray = resp?.typeActivities;
        this.schedulesArray = resp?.schedules;
        this.placesArray = resp?.places;
        this.commercialBusinessesArray = resp?.commercialBusinesses;
        this.numberChildrenArray = resp?.numberChildren;
        this.frequences = resp?.frequences;
        this.paymentmenthods = resp?.paymentmenthods;
        this.appsTaxis = resp?.appsTaxis;
        localStorage.setItem('commercialBusinessesArray', JSON.stringify(this.commercialBusinessesArray));
      });
  }

  getCivilStatus(): Observable<Lookup[]> {
    return of(this.maritalStatusArray);
  }

  getTypeOfDwelling(): Observable<Lookup[]> {
    return of(this.housingTypesArray);
  }

  getActivityType(): Observable<Lookup[]> {
    return of(this.typeActivitiesArray);
  }

  getSchedule(): Observable<Lookup[]> {
    return of(this.schedulesArray);
  }

  getPlace(): Observable<Lookup[]> {
    return of(this.placesArray);
  }

  getCommercialActivities(): Observable<LookupOrder[]> {
    return of(this.commercialBusinessesArray);
  }

  getNumberChildrenOptions(): Observable<Lookup[]> {
    return of(this.numberChildrenArray);
  }

  getFrequences(): Observable<LookupExtra[]> {
    return of(this.frequences);
  }

  getPaymentmenthods(): Observable<Lookup[]> {
    return of(this.paymentmenthods);
  }

  getAppsTaxis(): Observable<Lookup[]> {
    return of(this.appsTaxis);
  }

  saveLead(lead: LeadModel, isLogged: boolean = false): Observable<any> {
    let endpoint = isLogged ? 'intranet/lead' : 'lead';

    return this.httpClient.post(`${this.envBase}/${endpoint}`, lead, {
      headers: this.headerReCaptcha(lead.recaptcha!),
    });
  }

  sendStep1ToMailchimp(information: SeedCashMailchimpStep1): Observable<any> {
    const endpoint = `${this.envBase}/lead/mailchimp`;
    return this.httpClient.post(endpoint, information);
  }

  public updatePreapprovedPrecreditState(
    stepNumber: string,
    stepDescription: string,
    formType: string = FORM_TYPES.SEEDCASH
  ) {
    const data = {
      campaignType: '0',
      formType: formType,
      stepNumber,
      stepDescription,
    };
    this.financingService.updatePrecreditState(this.financingService.idPreCredit?.toString(), data).subscribe();
  }

  public updateIncompleteState(stepNumber: number, label: string, formType: string = FORM_TYPES.SEEDCASH) {
    if (isStateUpdateValid(stepNumber, this.currentPrecreditStep)) {
      this.currentPrecreditStep = stepNumber;
      this.updatePreapprovedPrecreditState(stepNumber.toString(), label, formType);
    }
  }

  public updateIncompleteStateSeed(stepNumber: number, label: string, formType: string = FORM_TYPES.SEEDCASH) {
      this.updatePreapprovedPrecreditState(stepNumber.toString(), label, formType);
  }

  /** Incomplete steps (data) */
  presaveLead(lead: LeadModel, isLogged: boolean = false): Observable<any> {
    let endpoint = isLogged ? 'intranet/lead' : 'lead';
    return this.httpClient.post(`${this.envBase}/${endpoint}/presaved`, lead, {});
  }

  updateIncompleteLead(idLead: number, lead: LeadModel): Observable<any> {
    return this.httpClient.put(`${this.envBase}/lead/${idLead}`, lead, {});
  }

  completeLead(idLead: number, lead: LeadModel): Observable<any> {
    return this.httpClient.put(`${this.envBase}/lead/${idLead}/complete`, lead, {
      headers: this.headerReCaptcha(lead.recaptcha!),
    });
  }

  /** Prefill external by OTP */
  hasPrefillData(data: any): Observable<{ flag: boolean }> {
    return this.httpClient.get<{ flag: boolean }>(`${this.envBase}/lead/latest-completed/exist?typeDocument=C&documentNumber=${data.documentNumber}&phone=${data.phone}`);
  }

  sendSMS(data: any): Observable<{ id: number }>{
    return this.httpClient.post<{ id: number }>(`${this.envBase}/sms/send`, data);
  }

  resendSMS(verificationId: number): Observable<any>{
    return this.httpClient.post(`${this.envBase}/sms/resend/${verificationId}`, {}, {observe: 'response'}).pipe(
      map(response => {
        if (response.status === 204) {
          return true;
        } else {
          return false;
        }
      })
    );
  }

  validateSMSCode(data: { verificationId: number, code: string }): Observable<boolean> {
    return this.httpClient.post<any>(`${this.envBase}/sms/validate`, data, { observe: 'response' }).pipe(
      map(response => {
        if (response.status === 204) {
          return true;
        } else {
          return false;
        }
      })
    );
  }

  getPrefillData(verificationId: number): Observable<any> {
    return this.httpClient.get(`${this.envBase}/lead/latest-completed/${verificationId}`);
  }

  validateLeadEligibility(data: any): Observable<any> {
    return this.httpClient.get<any>(`${this.envBase}/leadEligibility?typeDocument=C&documentNumber=${data.documentNumber}&phone=${data.phone}&email=${data.email}`);
  }

  postSaveBusinessDetail(leadBusinessDetailRequest: LeadBusinessDetailRequest): Observable<string> {
    return this.httpClient.post<string>(`${this.envBase}/lead/business-detail`, leadBusinessDetailRequest);
  }
  
  postUploadLeadFiles(file: preapproveImageModel, id: string): Observable<any> {
    const endpoint = `${this.envBase}/lead/${id}/upload`;
    return this.httpClient.post(endpoint, file);
  }

  deleteLeadFile(id: number): Observable<any> {
    const endpoint = `${this.envBase}/lead/file/${id}`;
    return this.httpClient.delete(endpoint);
  }
  sendNameSeed(name: string) {
    this.nameSubject.next(name);
  }
  enviarEstado(state: string) {
    this.stateSource.next(state);
  }

  updateStep(initialData: LeadModel): { pathBack: string; stepEnd: number, steps:string[]} {
    let pathBack = NEW_SEED_VALUES.pathPageOne;
    let stepEnd = 4;
    let steps = ['Datos', 'Contacto', 'Actividad Laboral', 'Ingresos y Gastos'];        
    if (initialData?.cboTipoDeActividad === 'Dependiente') {
      pathBack = NEW_SEED_VALUES.pathPageOne;
      stepEnd = NEW_SEED_VALUES.stepTotalDependient;
      steps = ['Datos', 'Contacto', 'Actividad Laboral'];
    } else {
      const data = this.formDataService.loadData('finishedStepOne');

      if (validateCodeComerce(data.codeComercialActivity)) {
        pathBack = NEW_SEED_VALUES.pathPageComerceInfo;
        stepEnd = NEW_SEED_VALUES.stepTotalComercio;
        steps = ['Datos', 'Contacto', 'Actividad Laboral', 'Ingresos', 'Gastos'];
      } else if (NEW_SEED_FLOW.taxiCode === data.codeComercialActivity) {
        pathBack = NEW_SEED_VALUES.pathPageTaxiInfo;
        stepEnd = NEW_SEED_VALUES.stepTotalComercio;
        steps = ['Datos', 'Contacto', 'Actividad Laboral', 'Ingresos', 'Gastos'];
      }
    }

    return { pathBack, stepEnd, steps };
  }
}
