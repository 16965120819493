import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
  HostListener,
} from '@angular/core';
import { NgbDropdown, NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { IDropdownItem, ISelectItem } from '@shared/interfaces/IDropdownItem';

@Component({
  selector: 'yevo-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent implements OnChanges {
  @Input() items!: IDropdownItem[];
  // Possible values for dropDownPlacement are "top", "top-left", "top-right",
  // "bottom", "bottom-left", "bottom-right", "left", "left-top", "left-bottom",
  // "right", "right-top", "right-bottom"
  @Input() dropdownPlacement: any[] = ['bottom-left', 'bottom-right', 'top-left', 'top-right'];
  @Input() buttonLabel?: string;
  @Input() itemSelected!: IDropdownItem;
  @Input() showItemsOnMobile = false;
  @Input() scrollActivated = false;
  @Input() menuHeightOnMobile = 'auto';
  @Input() containerTitle = '';
  @Input() disabled = false;
  @Input() searchable = false;
  @Input() showModalOnMobile = true;
  @Input() canRemoveActiveItem = true;
  @Input() customClass: string = '';
  @Input() showManualError: boolean = false;
  @Input() disabledStyle: boolean = false;
  @Output() clickDropdown = new EventEmitter();
  @Output() selectItem = new EventEmitter<ISelectItem>();
  @ViewChild(NgbDropdown) private dropdown!: NgbDropdown;

  selectedItem!: IDropdownItem;
  isMobileDropdownOpen = false;
  txtSearch: string = '';
  searchItemsAll!: IDropdownItem[];

  constructor(private eRef: ElementRef, private config: NgbDropdownConfig) {
    config.autoClose = true;
  }

  @HostListener('document:click', ['$event'])
  clickout(event: any) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.closeDropdownMenu();
      if (this.searchable) {
        this.clearSearch();
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const items: IDropdownItem[] = changes?.items?.currentValue;
    if (items && items.length) {
      this.selectedItem = this.itemSelected || null;
    }
    if (!items && !changes?.disabled && !changes?.showManualError) {
      this.selectedItem = null!;
    }
    if (changes?.itemSelected?.currentValue) {
      this.selectedItem = this.itemSelected;
    }
    if (this.searchable && this.items.length > 0) {
      this.searchItemsAll = this.items.map((x) => Object.assign({}, x));
    }
  }

  handleSelectItem(event: any, item: IDropdownItem): void {
    if (this.selectedItem?.value == item?.value) {
      // Remove active item if is the same pill clicked
      if (this.canRemoveActiveItem) {
        this.selectedItem = null!;
        event = null;
        item = null!;
      }
    } else {
      this.selectedItem = item;
    }
    this.selectItem.emit({
      event,
      item,
    });

    if (this.searchable) {
      this.clearSearch();
    }
  }

  async handleButtonClick(event: Event): Promise<void> {
    if (this.showItemsOnMobile) {
      document.body.style.overflow = 'hidden';
    }
    this.isMobileDropdownOpen = !this.isMobileDropdownOpen;

    this.clickDropdown.emit(event);
    if (this.searchable) {
      this.clearSearch();
    }
  }

  closeDropdownMenu(): void {
    if (this.showItemsOnMobile) {
      document.body.style.overflow = 'auto';
    }
    this.isMobileDropdownOpen = false;
    this.dropdown.close();
  }

  onSearchChange(searchValue: any): void {
    this.items = this.searchItemsAll.filter(
      (item) => item.label.toLowerCase().indexOf(searchValue?.value ? searchValue.value.toLowerCase() : '') > -1
    );
  }

  clearSearch(): void {
    this.txtSearch = '';
    this.onSearchChange('');
  }
}
